import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Accueil',
        action: 'manage',
        resource: 'All',
        breadcrumb: [
          {
            text: 'Accueil',
            active: true,
          },
        ],
      },
    },
    {
      path: '/prospects',
      name: 'prospects',
      component: () => import('@/views/prospects/prospects-list/ProspectsList.vue'),
      meta: {
        pageTitle: 'Prospects',
        action: 'manage',
        resource: 'All',
        breadcrumb: [
          {
            text: 'Prospects',
            active: true,
          },
        ],
      },
    },
    {
      path: '/platforms',
      name: 'platforms',
      component: () => import('@/views/platforms/platforms-list/PlatformsList.vue'),
      meta: {
        pageTitle: 'Plateformes',
        action: 'manage',
        resource: 'All',
        breadcrumb: [
          {
            text: 'Plateformes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/platforms/create',
      name: 'platforms-create',
      component: () => import('@/views/platforms/platforms-create/PlatformsCreate.vue'),
      meta: {
        pageTitle: 'Plateformes',
        action: 'manage',
        resource: 'All',
        breadcrumb: [
          {
            text: 'Plateformes',
            active: false,
            to: {
              name: 'platforms',
            },
          },
          {
            text: 'créer',
            active: true,
          },
        ],
      },
    },
    {
      path: '/platforms/:id',
      name: 'platforms-view',
      component: () => import('@/views/platforms/platforms-view/PlatformsView.vue'),
      meta: {
        pageTitle: 'Chargement...',
        action: 'manage',
        resource: 'All',
        breadcrumb: [{
          text: 'Plateformes',
          active: false,
          to: {
            name: 'platforms',
          },
        },
        {
          text: 'Chargement...',
          active: true,
        },
        ],
      },
    },
    {
      path: '/platforms/:id/edit',
      name: 'platforms-edit',
      component: () => import('@/views/platforms/platforms-edit/PlatformsEdit.vue'),
      meta: {
        pageTitle: 'Chargement...',
        action: 'manage',
        resource: 'All',
        breadcrumb: [{
          text: 'Plateformes',
          active: false,
          to: {
            name: 'platforms',
          },
        },
        {
          text: 'Chargement...',
          active: false,
        },
        {
          text: 'éditer',
          active: true,
        },
        ],
      },
    },
    {
      path: '/competitions',
      name: 'competitions',
      component: () => import('@/views/competitions/competitions-list/CompetitionsList.vue'),
      meta: {
        pageTitle: 'Competitions',
        action: 'manage',
        resource: 'All',
        breadcrumb: [
          {
            text: 'Competitions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/competitions/create',
      name: 'competitions-create',
      component: () => import('@/views/competitions/competitions-create/CompetitionsCreate.vue'),
      meta: {
        pageTitle: 'Competitions',
        action: 'manage',
        resource: 'All',
        breadcrumb: [
          {
            text: 'Competitions',
            to: {
              name: 'competitions',
            },
            active: false,
          },
          {
            text: 'créer',
            active: true,
          },
        ],
      },
    },
    {
      path: '/competitions/:id',
      name: 'competitions-view',
      component: () => import('@/views/competitions/competitions-view/CompetitionsView.vue'),
      meta: {
        pageTitle: 'Chargement...',
        action: 'manage',
        resource: 'All',
        breadcrumb: [{
          text: 'Competitions',
          active: false,
          to: {
            name: 'competitions',
          },
        },
        {
          text: 'Chargement...',
          active: true,
        },
        ],
      },
    },
    {
      path: '/competitions/:id/edit',
      name: 'competitions-edit',
      component: () => import('@/views/competitions/competitions-edit/CompetitionsEdit.vue'),
      meta: {
        pageTitle: 'Chargement...',
        action: 'manage',
        resource: 'All',
        breadcrumb: [{
          text: 'Competitions',
          active: false,
          to: {
            name: 'competitions',
          },
        },
        {
          text: 'Chargement...',
          active: false,
        },
        {
          text: 'éditer',
          active: true,
        },
        ],
      },
    },
    {
      path: '/competitions/:idCompetition/question/create',
      name: 'questions-create',
      component: () => import('@/views/competitions/competitions-view/questions-create/CompetitionsViewQuestionCreate.vue'),
      meta: {
        pageTitle: 'Ajouter question',
        action: 'manage',
        resource: 'All',
        breadcrumb: [{
          text: 'Competitions',
          active: false,
          to: {
            name: 'competitions',
          },
        },
        {
          text: 'Chargement...',
          active: true,
        },
        ],
      },
    },
    {
      path: '/competitions/:idCompetition/question/:id',
      name: 'questions-view',
      component: () => import('@/views/competitions/competitions-view/questions-view/CompetitionsViewQuestionsView.vue'),
      meta: {
        pageTitle: 'Chargement...',
        action: 'manage',
        resource: 'All',
        breadcrumb: [{
          text: 'Competitions',
          active: false,
          to: {
            name: 'competitions',
          },
        },
        {
          text: 'Chargement...',
          active: true,
        },
        ],
      },
    },
    {
      path: '/competitions/:idCompetition/fixture/:id',
      name: 'fixtures-view',
      component: () => import('@/views/competitions/competitions-view/fixtures-view/CompetitionsViewFixturesView.vue'),
      meta: {
        pageTitle: 'Chargement...',
        action: 'manage',
        resource: 'All',
        breadcrumb: [{
          text: 'Competitions',
          active: false,
          to: {
            name: 'competitions',
          },
        },
        {
          text: 'Chargement...',
          active: true,
        },
        ],
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/users/users-list/UsersList.vue'),
      meta: {
        pageTitle: 'Utilisateurs',
        action: 'manage',
        resource: 'All',
        breadcrumb: [
          {
            text: 'Utilisateurs',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/:id',
      name: 'users-view',
      component: () => import('@/views/users/users-view/UsersView.vue'),
      meta: {
        pageTitle: 'Chargement...',
        action: 'manage',
        resource: 'All',
        breadcrumb: [{
          text: 'Utilisateurs',
          active: false,
          to: {
            name: 'users',
          },
        },
        {
          text: 'Chargement...',
          active: true,
        },
        ],
      },
    },
    {
      path: '/users/:id/edit',
      name: 'users-edit',
      component: () => import('@/views/users/users-edit/UsersEdit.vue'),
      meta: {
        pageTitle: 'Chargement...',
        action: 'manage',
        resource: 'All',
        breadcrumb: [{
          text: 'Utilisateurs',
          active: false,
          to: {
            name: 'users',
          },
        },
        {
          text: 'Chargement...',
          active: false,
        },
        {
          text: 'éditer',
          active: true,
        },
        ],
      },
    },
    {
      path: '/messages',
      name: 'messages',
      component: () => import('@/views/messages/messages-list/MessagesList.vue'),
      meta: {
        pageTitle: 'Messages',
        action: 'manage',
        resource: 'All',
        breadcrumb: [
          {
            text: 'Messages',
            active: true,
          },
        ],
      },
    },
    {
      path: '/alerts',
      name: 'alerts',
      component: () => import('@/views/alerts/alerts-list/AlertsList.vue'),
      meta: {
        pageTitle: 'Alertes',
        action: 'manage',
        resource: 'All',
        breadcrumb: [
          {
            text: 'Alertes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/alerts/create',
      name: 'alerts-create',
      component: () => import('@/views/alerts/alerts-create/AlertsCreate.vue'),
      meta: {
        pageTitle: 'Alertes',
        action: 'manage',
        resource: 'All',
        breadcrumb: [
          {
            text: 'Alertes',
            to: {
              name: 'alerts',
            },
            active: false,
          },
          {
            text: 'créer',
            active: true,
          },
        ],
      },
    },
    {
      path: '/alerts/:id',
      name: 'alerts-view',
      component: () => import('@/views/alerts/alerts-view/AlertsView.vue'),
      meta: {
        pageTitle: 'Chargement...',
        action: 'manage',
        resource: 'All',
        breadcrumb: [{
          text: 'Alertes',
          active: false,
          to: {
            name: 'alerts',
          },
        },
        {
          text: 'Chargement...',
          active: true,
        },
        ],
      },
    },
    {
      path: '/alerts/:id/edit',
      name: 'alerts-edit',
      component: () => import('@/views/alerts/alerts-edit/AlertsEdit.vue'),
      meta: {
        pageTitle: 'Chargement...',
        action: 'manage',
        resource: 'All',
        breadcrumb: [{
          text: 'Alertes',
          active: false,
          to: {
            name: 'alerts',
          },
        },
        {
          text: 'Chargement...',
          active: false,
        },
        {
          text: 'éditer',
          active: true,
        },
        ],
      },
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    // ! We updated login route name here from `auth-login` to `login` in starter-kit
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser())
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router

import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    competition: {},
  },
  getters: {
    competition(state) {
      return state.competition
    },
  },
  mutations: {
    SET_COMPETITION(state, val) {
      state.competition = val.data
      state.competition.platforms_slot_sum = val.competition_platforms_slot_sum
      state.competition.platforms_users_count = val.competition_platforms_users_count
      state.competition.competition_forecasts_count = val.competition_forecasts_count
    },
  },
  actions: {
    fetchCompetition({ commit }, uuid) {
      return axiosIns.get(`competitions/${uuid}`)
        .then(result => {
          commit('SET_COMPETITION', result.data)
        })
    },
  },
}

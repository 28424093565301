import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import platforms from './platforms'
import users from './users'
import competitions from './competitions'
import alerts from './alerts'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    platforms,
    users,
    competitions,
    alerts,
  },
  strict: process.env.DEV,
})

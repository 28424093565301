import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    user: {},
  },
  getters: {
    user(state) {
      return state.user
    },
  },
  mutations: {
    SET_USER(state, val) {
      state.user = val
    },
  },
  actions: {
    fetchUser({ commit }, uuid) {
      return axiosIns.get(`users/${uuid}`)
        .then(result => {
          commit('SET_USER', result.data.data)
        })
    },
  },
}

import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    alert: {},
  },
  getters: {
    alert(state) {
      return state.alert
    },
  },
  mutations: {
    SET_ALERT(state, val) {
      state.alert = val
    },
  },
  actions: {
    fetchAlert({ commit }, uuid) {
      return axiosIns.get(`alerts/${uuid}`)
        .then(result => {
          commit('SET_ALERT', result.data.data)
        })
    },
  },
}

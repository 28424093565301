import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    platform: {},
  },
  getters: {
    platform(state) {
      return state.platform
    },
  },
  mutations: {
    SET_PLATFORM(state, val) {
      state.platform = val
    },
  },
  actions: {
    fetchPlatform({ commit }, uuid) {
      return axiosIns.get(`platforms/${uuid}`)
        .then(result => {
          commit('SET_PLATFORM', result.data.data)
        })
    },
  },
}

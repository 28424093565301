import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'

const { jwt } = useJwt(axios, {
  loginEndpoint: '/auth/login',
  refreshEndpoint: '/auth/refresh',
  logoutEndpoint: '/auth/logout',
  storageTokenKeyName: 'access_token',
  storageRefreshTokenKeyName: 'access_token',
})
export default jwt
